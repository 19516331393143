const prodEnvironment = {
  production: true,
  backendRootUrl: 'https://schrankomat.pixxprint.r0k.space',
  frontendRootUrl: 'https://admin-panel.pixxprint.r0k.space/',
  keycloak: {
    // TODO This needs to be updated once we have a production keycloak
    serverUrl: 'https://keycloak.r0k.de',
    clientId: 'schrankomat_prod',
    realmName: 'pixxprint',
  },
};

const stagingEnvironment = {
  production: true,
  backendRootUrl: 'https://schrankomat.schrankkonfigurator.qa.r0k.dev',
  frontendRootUrl: 'https://admin-panel.schrankkonfigurator.qa.r0k.dev/mockclient/',
  keycloak: {
    serverUrl: 'https://keycloak.r0k.dev',
    clientId: 'schrankomat_staging_qa',
    realmName: 'pixxprint',
  },
};

let selectedEnvironment = prodEnvironment;

if (window.location.hostname === 'admin-panel.schrankkonfigurator.qa.r0k.dev') {
  selectedEnvironment = stagingEnvironment;
}

export const environment = selectedEnvironment;
